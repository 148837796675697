import React from "react"
import { ChevronDown, ChevronUp, ChevronRight } from "react-feather"
import buttons from "../../styles/buttons.module.css"

type CMSSubNavProps = {
  name: string
  items: Array<itemsType>
  id: number
  title: string
  open: boolean
  openTrigger: (id: number) => Function
  itemType: string
  allLink: string
}

type itemsType = {
  id: number
  title: string
  path: string
  excerpt: string
  image: string
}

export const CMSSubNav = ({
  name,
  items,
  id,
  open,
  openTrigger,
  itemType,
  allLink,
}: CMSSubNavProps) => {
  return (
    <li
      tabIndex={0}
      key={`navigation-item-${id}`}
      data-testid="subLinkMenu"
      data-cy={`subLinkMenu-${name}`}
      className="mr-0"
      onClick={e => {
        if (open) {
          openTrigger(0)
        } else {
          e.stopPropagation()
          openTrigger(id)
        }
      }}
    >
      <span className="flex items-center">
        {name}
        {open ? <ChevronUp size="16" /> : <ChevronDown size="16" />}
      </span>

      <div
        onClick={e => e.stopPropagation()}
        className={`${
          open ? "block bg-transparent pointer-events-none" : "hidden"
        }`}
      >
        <div className="container mx-auto overflow-scroll h-three-quarter-screen sm:px-8 md:px-12 lg:px-6 xl:px-16">
          <div className="px-12 py-12 bg-white pointer-events-auto">
            <div className="grid grid-cols-12">
              <div className="col-span-12">
                <ul className="grid grid-cols-3 gap-6">
                  {items.map(({ id, title, path, excerpt, image }) => (
                    <li className="mr-0" key={`CMSSubNavItem-${id}`}>
                      <img src={image} alt="" className="h-8 mb-2" />
                      <p className="mb-0 font-bold text-gray-900 text-md">
                        {title}
                      </p>
                      <p className="flex-grow mb-3 text-md">{excerpt}</p>
                      <a href={path} className="flex items-center text-s">
                        {`View ${itemType}`}
                        <ChevronRight size="16" />
                      </a>
                    </li>
                  ))}
                </ul>
                <a
                  data-cy={`subLinkViewAll-${name}`}
                  className={`mt-8 inline-flex items-center ${buttons.btnSecondaryOutline}`}
                  href={allLink}
                >
                  {`View all ${itemType}s`}
                  <ChevronRight size="16" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}
