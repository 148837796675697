import React from "react"
import { Link } from "gatsby"
import { Linkedin, Facebook, Instagram, Send } from "react-feather"
import logo from "assets/icons/logo.svg"

type FooterProps = {
  siteTitle: string
  description: string
  email: string
  telephone: string
}

export const Footer = ({ email }: FooterProps) => {
  return (
    <div>
      <div className="bg-white">
        <div className="container px-6 py-16 mx-auto sm:px-8 xl:px-12">
          <div className="xl:px-4">
            <div className="grid grid-cols-1 gap-10 md:gap-6 md:grid-cols-3 lg:grid-cols-12">
              <div className="flex items-center justify-center md:block md:col-span-3 lg:col-span-4 lg:items-left">
                <div className="text-center lg:text-left">
                  <Link to="/" className="inline-block mb-8">
                    <span className="sr-only">Dr Yaldas Logo</span>
                    <img src={logo} className="w-64" alt="Dr Yalda logo" />
                  </Link>
                  <div className="mb-4">
                    <a href="https://www.facebook.com/DrYalda.Aesthetics/">
                      <span className="sr-only">Dr Yaldas Facebook</span>
                      <Facebook size="24" className="inline mr-2" />
                    </a>
                    <a href="https://www.instagram.com/dryaldaclinics/?hl=en">
                      <span className="sr-only">Dr Yaldas Instagram</span>
                      <Instagram size="24" className="inline ml-2 mr-2" />
                    </a>
                    <a href="https://www.linkedin.com/in/yaldajamali/">
                      <span className="sr-only">Dr Yaldas LinkedIn</span>
                      <Linkedin size="24" className="inline ml-2 mr-2" />
                    </a>
                  </div>
                  <div className="grid grid-cols-12 gap-6">
                    <form
                      name="newsletter"
                      method="post"
                      action="/success"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      className="col-span-12 mt-4 md:col-span-6 md:col-start-4 lg:col-span-12 lg:col-start-1"
                    >
                      <div className="flex">
                        <input
                          type="hidden"
                          name="form-name"
                          value="newsletter"
                        />
                        <input type="hidden" name="bot-field" />
                        <label className="flex flex-grow">
                          <span className="sr-only">Sign up</span>
                          <input
                            className="flex-grow pl-2 placeholder-black bg-transparent border-b-2 border-gray-300 border-solid lg:w-full"
                            type="email"
                            placeholder="Email address"
                            id="newsletter-signup"
                            name="newsletter-signup"
                            required
                          />
                        </label>
                        <button className="p-3 text-white bg-black">
                          <span className="sr-only">Apply for newsletter</span>
                          <Send />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-2 lg:col-start-6">
                <nav className="text-center lg:text-left">
                  <h3 className="py-3 font-sans text-base font-semibold lg:text-lg lg:text-left">
                    Treatments
                  </h3>
                  <ol>
                    <li className="py-1">
                      <Link
                        to="/treatments/soft-tissue-fillers/"
                        className="text-base text-center"
                      >
                        Soft Tissue Fillers
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        to="/treatments/chemical-peels/"
                        className="text-base text-center"
                      >
                        Chemical Peels
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        to="/treatments/lines-and-wrinkles/"
                        className="text-base text-center"
                      >
                        Lines and Wrinkles
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        to="/treatments/medical-grade-skincare/"
                        className="text-base text-center"
                      >
                        Medical Grade Skincare
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        to="/treatments/microneedling/"
                        className="text-base text-center"
                      >
                        Microneedling
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        to="/treatments/profhilo/"
                        className="text-base text-center"
                      >
                        Profhilo
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="lg:col-span-2">
                <nav className="text-center lg:text-left">
                  <h3 className="py-3 font-sans text-base font-semibold lg:text-lg lg:text-left">
                    Prices
                  </h3>
                  <ol>
                    <li className="py-1">
                      <a href="/assets/nottingham-price-list.png" download>
                        Nottingham
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="lg:col-span-2">
                <nav className="text-center">
                  <h3 className="py-3 font-sans text-base font-semibold lg:text-lg lg:text-left">
                    Get in touch
                  </h3>
                  <div>
                    <a
                      className="block py-3 text-base lg:text-left"
                      href={`mailto:${email}`}
                    >
                      {email}
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100">
          <div className="container px-6 mx-auto sm:px-8 md:px-12">
            <div className="justify-between md:flex">
              <p className="py-4 text-gray-700">
                ©{new Date().getFullYear()} - Dr Yalda | All rights reserved.
              </p>
              <p className="pt-1 pb-4 text-base text-gray-700 md:py-4">
                Made by{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="py-4 text-gray-800 underline"
                  href="https://www.instagram.com/jessb.designer/?hl=en"
                >
                  Jessica Brownless
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
