import React from "react"
import { Link } from "gatsby"
import { Menu } from "react-feather"
import { CMSSubNav } from "./CMSSubNav"
import { SubMenuItem } from "./SubMenuItem"
import { useStaticQuery, graphql } from "gatsby"
import { useScroll } from "../../utils/functions"
import styles from "./header.module.css"
import buttons from "../../styles/buttons.module.css"
import logo from "../../assets/icons/logo.svg"

type HeaderProps = {
  siteTitle: string
  navLinks: Array<{
    name: string
    page: string
    id: number
    subLinks?: Array<{ name: string; page: string; id: number }>
  }>
  navToggle: () => Function
  pageSelected: string
  openMenu: number
  menuToggle: () => Function
}

const Navigation = ({ navLinks, pageSelected, openMenu, menuToggle }) => {
  return (
    <nav className={styles.headerNavigation}>
      <ol>
        {navLinks.map(({ name, page, subLinks, id, products }) => {
          if (products) {
            const data = useStaticQuery(graphql`
              query {
                allFile(filter: { sourceInstanceName: { eq: "products" } }) {
                  edges {
                    node {
                      childMarkdownRemark {
                        frontmatter {
                          id
                          title
                          path
                          excerpt
                          image
                        }
                      }
                    }
                  }
                }
              }
            `)

            const productsDestructured = data.allFile.edges.map(
              obj =>
                // @ts-ignore: graphql data
                obj.node.childMarkdownRemark.frontmatter
            )

            return (
              <CMSSubNav
                key={`navigation-item-${id}`}
                name={name}
                title="Treatments"
                items={productsDestructured}
                id={id}
                open={openMenu === id}
                openTrigger={menuToggle}
                itemType="treatment"
                allLink="/treatments"
              />
            )
          }

          if (subLinks && subLinks.length > 0) {
            return (
              <SubMenuItem
                key={`navigation-item-${id}`}
                name={name}
                subLinks={subLinks}
                id={id}
                open={openMenu === id}
                openTrigger={menuToggle}
              />
            )
          }

          return (
            <li
              className={`${
                pageSelected === name ? "border-b-4 text-gray-900" : ""
              }`}
              data-testid="headerNavigationLink"
              key={`navigation-item-${id}`}
            >
              <Link to={`${page}`}>{name}</Link>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export const Header = ({
  siteTitle,
  navLinks,
  navToggle,
  pageSelected,
  openMenu,
  menuToggle,
}: HeaderProps) => {
  const { scrollY, scrollDirection } = useScroll()

  return (
    <div
      className={`${scrollY > 500 ? styles.headerContainerFixed : ""}
      ${
        scrollY > 500 && scrollDirection === "down"
          ? styles.headerContainerRetreat
          : ""
      }
       ${styles.headerContainer}`}
    >
      <div
        className={`container px-6 md:px-0 mx-auto lg:px-6 xl:px-12 ${styles.header}`}
      >
        <div className="xl:px-4">
          <Menu onClick={navToggle} className={styles.headerMenuIcon} />
          <div className={styles.headerLogo}>
            <Link to="/">
              <img src={logo} alt={siteTitle} />
            </Link>
          </div>

          <Navigation
            navLinks={navLinks}
            pageSelected={pageSelected}
            openMenu={openMenu}
            menuToggle={menuToggle}
          />
          <div className={styles.headerCTA}>
            <Link
              className={`${buttons.btnPrimary} ${buttons.btnSmall} ml-6 shadow-md`}
              to="https://app.sellsio.co/online-bookings/38b43b72b261477ab2ca9ea2634ee240"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book online
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
