import React from "react"
import whatsapp from "../../assets/icons/whatsapp.svg"

type WhatsappProps = {
  telephone: string
}

export const Whatsapp = ({ telephone }: WhatsappProps) => {
  return (
    <a
      className="fixed flex justify-center flex-shrink-0 w-12 h-12 overflow-hidden bg-green-500 rounded-full bottom-1 right-1 align-center"
      target="_blank"
      rel="noopener noreferrer"
      href={`https://wa.me/${telephone}/`}
    >
      <img src={whatsapp} alt="Whatsapp Logo" />
    </a>
  )
}
